import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import { IoIosArrowForward } from "react-icons/io";


const Understandingndis = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 

    return (
        <div>
            <PageTitle title="Understanding NDIS - Nexus Living SA"/>
            <Header/>
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

            <div className='ndis-page bg-[#fff] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>

            <div className='w-8/12 md-lg:w-full sm:w-full pr-10 md-lg:mb-6 md-lg:pr-0 sm:pr-0'>
            <h2 className='font-normal text-3xl text-[#333] pb-3 md-lg:text-[24px]'>What is the NDIS?</h2>
            <p className='text-md text-[#68666C] mb-3' >The <span className='font-bold'>National Disability Insurance Scheme (NDIS)</span> provides support to people with disability, their families and carers.</p>
            <p className='text-md text-[#68666C] mb-3' >The main component of the NDIS is individual packages of funding and financial support to eligible people with disability. Some people may be eligible for NDIS funding, but are transitioning from state based services. To be eligible for SDA, participants must have a current NDIS plan. Click on either of the links below for more information.</p>
            <Link className='px-6 py-3 bg-[#32324e] text-white rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]'>Contact Us</Link>
            </div>

            <div className='w-4/12 md-lg:w-full sm:w-full'>
            <img className='w-full md-lg:w-full' src="https://nexuslivingsa.com.au/images/ndis-resourcesimg1.jpg" alt="" />
            </div>
            
        </div>
        </div>
        </div>


        <div className='ndis-page bg-[#f0f0f0] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>

            <div className='w-8/12 md-lg:w-full sm:w-full pr-10 md-lg:mb-6 md-lg:pr-0 sm:pr-0'>
            <h2 className='font-normal text-3xl text-[#333] pb-3 md-lg:text-[24px]'>Frequently Asked Questions.</h2>
            <p className='text-md text-[#68666C] mb-3' >We’ve answered some of the most frequently asked questions about NDIS, eligibility, and Service Agreements, which could help you get started on your SDA journey.</p>
            <p className='text-md text-[#68666C] mb-3' >If you’ve got a question we haven’t answered, <Link className='font-semibold'>get in touch</Link> and we’ll help you with whatever info you’re after.</p>
            <h3 className='font-normal mt-6 text-2xl text-[#68666C] pb-3 md-lg:text-[20px] flex'> <span className='text-[#68666C] mt-1 mr-2'><FaArrowRight /></span> Who is Eligible for the NDIS?</h3>
            <p className='text-md text-[#68666C] mb-3' >Eligibility for the NDIS will be determined by 3 main criteria. To qualify for the National Disability Insurance Scheme (NDIS) you should answer yes to the following:</p>
            <small className='text-[#68666C] mb-3'> Are you aged between 7 and 65? (Excludes NDIS for Early Intervention). Do you live in Australia and are either an Australian Citizen, or hold a permanent visa, or hold a special category visa? </small>

            <h3 className='font-normal mt-6 text-2xl text-[#68666C] pb-3 md-lg:text-[20px] flex'> <span className='text-[#68666C] mt-1 mr-2'><FaArrowRight /></span>  Who is Eligible for the NDIS?</h3>
            <p className='text-md text-[#68666C] mb-3' >Eligibility for the NDIS will be determined by 3 main criteria. To qualify for the National Disability Insurance Scheme (NDIS) you should answer yes to the following:</p>
            <small className='text-[#68666C] mb-3'>  Are you aged between 7 and 65? (Excludes NDIS for Early Intervention). Do you live in Australia and are either an Australian Citizen, or hold a permanent visa, or hold a special category visa?  </small>

            <h3 className='font-normal mt-6 text-2xl text-[#68666C] pb-3 md-lg:text-[20px] flex'> <span className='text-[#68666C] mt-1 mr-2'><FaArrowRight /></span> Who is Eligible for the NDIS?</h3>
            <p className='text-md text-[#68666C] mb-3' >Eligibility for the NDIS will be determined by 3 main criteria. To qualify for the National Disability Insurance Scheme (NDIS) you should answer yes to the following:</p>
            <small className='text-[#68666C] mb-3'> Are you aged between 7 and 65? (Excludes NDIS for Early Intervention). Do you live in Australia and are either an Australian Citizen, or hold a permanent visa, or hold a special category visa? </small>
            </div>

            <div className='w-4/12 md-lg:w-full sm:w-full'>
            <img className='w-full md-lg:w-full' src="https://nexuslivingsa.com.au/images/ndis-img2.jpg" alt="" />
            </div>
            
        </div>
        </div>
        </div>

       

            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Understandingndis;