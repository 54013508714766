import React, { useEffect, useState } from 'react';
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF, FaList } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io"; 



const Header = () => {

    
 




    const {pathname} = useLocation()
    const [showShidebar, setShowShidebar] = useState(true);
    const [categoryShow, setCategoryShow] = useState(true);
    
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

           
    /* Method that will fix header after a specific scrollable */
           const isSticky = (e) => {
                const header = document.querySelector('.header-section');
                const scrollTop = window.scrollY;
                scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
            };
    

    return (
        <div className='w-full bg-white'>
            <div className='top-header bg-[#ad9b8145] overflow-hidden'>
            
                
             <div className='w-[85%] lg:w-[90%] mx-auto'>
                <div className='flex w-full justify-end md-lg:justify-center h-[36px] items-center text-slate-500'>
                  <ul className='flex justify-start items-center gap-8 text-[#333] md-lg:gap-3'>
                  <li className='flex relative justify-center items-center gap-2 text-sm md-lg:after:hidden after:absolute after:h-[18px] after:w-[1px] after:bg-[#afafaf] after:-right-[16px]'>
                    <a className='flex gap-2 md-lg:gap-0 leading-3 hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]' href='mailto: info@nexuslivingsa.com.au'><span><MdOutlineMail /></span>  info@nexuslivingsa.com.au</a>
                  </li>

                <li className='flex relative justify-center items-center gap-2 text-sm '>
                <a className='flex gap-2 md-lg:gap-0 leading-3 hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]' href='tel:+61 451 661 698'><span><FaPhoneAlt /></span>  +61 451 661 698</a>
                </li>
                  </ul>
                </div>
             </div>
            
            
            </div>

            <div className={`bg-[#32324e] header-section d-none d-xl-block`}>
         <div className='w-[85%] lg:w-[90%] mx-auto flex justify-between relative'>
            <div className='flex justify-between items-center flex-wrap w-full'>
        
            <div className='flex justify-between items-center md-lg:w-full '>
            <div className='headstick flex justify-between items-center w-[190px] md-lg:w-[150px]'>

                <Link to='/'>
                    <img src="https://nexuslivingsa.com.au/images/logo/logo5.png" alt="" />
                </Link>
                
                </div> 
                <div className='justify-center items-center w-[35px] h-[35px]  text-slate-300 border border-slate-500 rounded-sm cursor-pointer lg:hidden md-lg:flex xl:hidden hidden' onClick={() => setShowShidebar(false)}>
                    <span> <FaList/> </span>
                </div>
                </div> 
                

            
                <div className='flex justify-between md-lg:justify-center md-lg:hidden items-center flex-wrap pl-8'>
                    <ul className='flex justify-start font-family items-start lg:gap-0 xxl:gap-0 gap-6 text-sm font-normal uppercase md-lg:hidden relative'>
                        <li>
                            <Link to='/' className={`p-2 lg:px-1 block ${pathname === '/' ?  'text-[#ae9c81]' : 'text-white' } `} >Home</Link>
                        </li>

                        <li>
                            <Link className={`p-2 lg:px-1 group gap-1 flex ${pathname === '' ?  'text-[#ae9c81]' : 'text-white' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Who we are 
                            <span className='text-lg'><MdKeyboardArrowDown /></span>
                            <div className='hidden group-hover:block absolute w-[245px] top-[36px] left-[90px] right-0 bg-white shadow-lg hover:transition-all hover:ease-out hover:duration-500 z-50'>
<ul className='ml-2 mr-2 mt-2'>
  <li> <Link to='/who-we-are/values-mission' className={`${pathname === '/who-we-are/values-mission' ?  'text-[#ae9c81]' : 'text-[#333]' } text-[#333] p-2 w-full flex border-b-[1px] border-[#eee] capitalize hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500`}>Our Values & Mission</Link></li>
  <li> <Link to='/who-we-are/what-we-do' className={`${pathname === '/who-we-are/what-we-do' ?  'text-[#ae9c81]' : 'text-[#333]' } text-[#333] p-2 w-full flex border-b-[1px] border-[#eee] capitalize hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500`}>What We do </Link></li>
  <li> <Link to='/who-we-are/meet-our-team' className={`${pathname === '/who-we-are/meet-our-team' ?  'text-[#ae9c81]' : 'text-[#333]' } text-[#333] p-2 w-full flex border-b-[1px] border-[#eee] capitalize hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500`}>Meet Our Team </Link></li>
  <li> <Link to='/who-we-are/collaborate-with-us' className={`${pathname === '/who-we-are/collaborate-with-us' ?  'text-[#ae9c81]' : 'text-[#333]' } text-[#333] p-2 w-full flex border-b-[1px] border-[#eee] capitalize hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500`}>Collaborate with us </Link></li>
 </ul>
 </div>

 
                             </Link>
                        </li>
                        <li>
                            <Link to='/ourhome' className={`p-2 lg:px-1 block ${pathname === '/ourhome' ?  'text-[#ae9c81]' : 'text-white' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Our Homes</Link>
                        </li>
                        <li>
                            <Link to='/eligibility' className={`p-2 lg:px-1 block ${pathname === '/eligibility' ?  'text-[#ae9c81]' : 'text-white' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Eligibility</Link>
                        </li>
                        <li>
                            <Link to='/understanding-ndis' className={`p-2 lg:px-1 block ${pathname === '/understanding-ndis' ?  'text-[#ae9c81]' : 'text-white' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Understanding the NDIS</Link>
                        </li>
                        <li>
                            <Link to='/contactus' className={`p-2 lg:px-1 block ${pathname === '/contactus' ?  'text-[#ae9c81]' : 'text-white' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Contact Us</Link>
                        </li>

                    </ul>

                   
                </div>
                
            </div>
            </div>
        </div>

        <div className='hidden md-lg:block'>
        <div onClick={()=> setShowShidebar(true)} className={`fixed duration-500 transition-all ${showShidebar ? 'invisible' : 'visible'} hidden md-lg:block w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0 z-[20] scroll-smooth `}>  
        </div> 

        


        <div className={`w-[270px] z-[9999] transition-all duration-500 fixed ${showShidebar ? '-left-[300px]' : 'left-0 top-0'} overflow-y-auto bg-white h-screen py-4 px-5 top-0 scroll-smooth `}>

            <div className='flex justify-end items-center'>
            <div className='items-center justify-center flex w-[30px] h-[30px] bg-white text-slate-600 border border-slate-600 rounded-sm cursor-pointer' onClick={() => setShowShidebar(true)}>
                    <span> <RxCross2 /> </span>
                </div>
            </div>

                <div className='flex justify-start flex-col gap-6'>
                <Link to='/'>
                    <h2 className='text-[22px] font-semibold'>Nexus Living SA</h2>
                </Link>
  

                
                <ul className='flex flex-wrap justify-start font-family items-start lg:gap-0 xxl:gap-0 gap-6 text-sm font-normal'>
                        <li className='flex flex-col w-full'>
                            <Link to='/' className={`p-2 block flex-col ${pathname === '/' ?  'text-[#ae9c81]' : 'text-[#333]' } `} >Home</Link>
                        </li>

                         <li className='flex flex-col w-full'>
                            <Link onClick={() => setCategoryShow(!categoryShow) } className={`p-2 gap-1 flex ${pathname === '' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Who we are 
                            <span className='pt-1'><IoIosArrowDown /></span>
                            </Link>

                            <div className='bg-white relative'>
                            <div className={`${categoryShow ? 'h-0' : 'h-[155px]'} overflow-hidden transition-all md-lg:relative duration-500 absolute z-[99999] bg-[#eee] w-full border-x`}>
                           <ul className='py-2 text-slate-600 font-medium'>
                           <li className='flex flex-col w-full'>
                            <Link to='who-we-are/values-mission' className={`p-2 border-b-[1px] border-[#ccc] flex ${pathname === '/who-we-are/values-mission' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Our Values & Mission</Link>
                          </li>
                          <li className='flex flex-col w-full'>
                            <Link to='/who-we-are/what-we-do' className={`p-2 border-b-[1px] border-[#ccc] flex ${pathname === '/who-we-are/what-we-do' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >What We do </Link>
                          </li>
                          <li className='flex flex-col w-full'>
                            <Link to='/who-we-are/meet-our-team' className={`p-2 border-b-[1px] border-[#ccc] flex ${pathname === '/who-we-are/meet-our-team' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Meet Our Team </Link>
                          </li>
                          <li className='flex flex-col w-full'>
                            <Link to='/who-we-are/collaborate-with-us' className={`p-2 border-b-[1px] border-[#ccc] last:border-none flex ${pathname === '/who-we-are/collaborate-with-us' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Collaborate with us  </Link>
                          </li>
                          </ul>
                         </div>
                        </div>


                            
                        </li>
                        <li className='flex flex-col w-full'>
                            <Link to='/ourhome' className={`p-2 flex ${pathname === '/ourhome' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Our Homes</Link>
                        </li>
                        <li className='flex flex-col w-full'>
                            <Link to='/eligibility' className={`p-2 flex ${pathname === '/eligibility' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Eligibility</Link>
                        </li>
                        <li className='flex flex-col w-full'>
                            <Link to='/understanding-ndis' className={`p-2 flex ${pathname === '/understanding-ndis' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Understanding the NDIS</Link>
                        </li>
                        <li className='flex flex-col w-full'>
                            <Link to='/contactus' className={`p-2 flex ${pathname === '/contactus' ?  'text-[#ae9c81]' : 'text-[#333]' } hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500 `} >Contact Us</Link>
                        </li>

                    </ul>
                
 
                    <div className='flex justify-start items-center gap-4 text-black'>
                                    <a href="FaFacebookF"><FaFacebookF /> </a>
                                    <a href="FaTwitter"><FaTwitter /> </a>
                                    <a href="FaLinkedin"><FaLinkedin /> </a>
                                   
                                
                    </div>
                    <div className='w-full flex justify-end md-lg:justify-start gap-3 items-center'>
        <div className='w-[48px] h-[48px] rounded-full flex bg-[#f5f5f5] justify-center items-center '>
        <span><FaPhoneAlt /></span>
        </div>
        <div className='flex justify-end flex-col gap-1'>
            <h2 className='text-sm font-medium text-slate-700'>+61 451 751 617</h2>
            <span className='text-xs'>Support 24/7</span> 
        </div>
        </div>

    

                </div> 
                </div>
               </div>



        </div>
    );
};

export default Header;