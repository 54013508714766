import React, { useEffect, useState } from 'react';
import axios from 'axios';




const Package = () => {

// State to store the fetched data // start Axios
const [data, setData] = useState([]);

// Function to fetch data using Axios
const fetchData = async () => {
  try {
    const response = await axios.get("https://nexuslivingsa.com.au/backend/public/api/service");
    setData(response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  
};

// Call fetchData on component mount
useEffect(() => {
  fetchData();
}, []);

// End Axios





    return (



      
        <div className='w-full'>

       
            <div className='package-blog bg-[#32324e] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-center items-center flex-col'>

            <div className='header flex justify-center items-center flex-col'>
            <h2 className='font-semibold text-3xl text-[#fff] text-center pb-3 md-lg:text-[24px]'>Our NDIS housing option packages include the following:</h2>
            <div className='w-[170px] items-center flex justify-center flex-col'>
                <div className='w-[170px] h-[1px] bg-[#a3a3a3]'></div>
                <div className='w-[50px] h-[7px] bg-[#ae9c81] relative bottom-1'></div>
            </div>
            </div>

           

           <div className='flex flex-wrap mt-10'>

           {data.map((post, i) => (
               
            
            <div key={i} className='w-4/12 md-lg:w-full md-lg:mb-5'>
              <div className='pack-box border-[1px] border-[#fff] mx-3'>
                
                <img src={`https://nexuslivingsa.com.au/backend/public/${post.portfolio_image}`} alt={post.portfolio_image} />
              <div className='pack-inner p-6 min-h-[240px] md-lg:min-h-full bg-white'>
                <h2 className='font-semibold text-2xl text-[#333] pb-3 md-lg:text-[22px]'>{post.portfolio_name} </h2>
                <p className='text-md text-[#68666C] mb-3'> {post.portfolio_title}</p>
              </div>
              </div>
            </div>
            
       
            ))}

           </div>
          
            
        </div>
        </div>
        </div>
        
     


        </div>

        
    );
};

export default Package;