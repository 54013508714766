import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Whoweare from '../components/Whoweare';
import Package from '../components/Package';
import Homeother from '../components/Homeother';
import Footer from '../components/Footer';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import Loading from '../components/Loading';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])  

      const [isLoading, setIsLoading] = useState(true);
 
      setTimeout(() => {
          setIsLoading(false);
      }, 2000);


    return (
        <div className='w-full'>
            <PageTitle title="Home - Nexus Living SA" />
           <Header />
           <Banner />
           <Whoweare />

           {
             isLoading ? ( <Loading/> ) : (
           <Package />
         )
        }

           <Homeother />
           <Footer />
           <GoToTop/>
        </div>
    );
};

export default Home;