import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import { IoIosArrowForward } from "react-icons/io";


const Eligibility = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 

    return (
        <div>
            <PageTitle title="Eligibility - Nexus Living SA"/>
            <Header/>
            
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

            <div className='ndis-page bg-[#fff] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>

            <div className='w-8/12 md-lg:w-full sm:w-full pr-10 md-lg:mb-6 md-lg:pr-0 sm:pr-0'>
            
            <div className='boxshadowone p-6'>
            <h2 className='font-normal text-3xl text-[#333] pb-2 md-lg:text-[24px]'>Tell Us About You</h2>
            <p className='text-md text-[#68666C] mb-6' >Complete the fields below and we will be in touch! </p>
            <form>
            <div className='flex flex-wrap md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="name">Enter Your Name </label>
                <input type="text" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="name" id="name" placeholder='Enter Your Name' /> 
            </div>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="phone"> Enter Your Phone </label>
                <input type="text" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="phone" id="phone" placeholder='Enter Your Phone' /> 
            </div>
            </div>
            <div className='flex flex-wrap md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
            <label htmlFor="state"> State </label>
            <select className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="" id="">
                            <option value="">SA</option>
                            <option value="">SA</option>
                        </select> 
            </div>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="post"> Organisation Name </label>
                <input type="text" className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="post" id="post" placeholder='Organisation Name' /> 
            </div> 
            </div>
            <div className='flex flex-wrap md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
            <label htmlFor="state"> How did you hear about Sana Living </label>
            <select className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="" id="">
                            <option value="">Friend/Family</option>
                            <option value="">Google</option>
                            <option value="">Health/Medical Professional</option>
                            <option value="">Support Coordinator</option>
                            <option value="">Social Media</option>
                            <option value="">Website Advert</option>
                            <option value="">Other</option>
                        </select> 
            </div>
            </div>
            <div className='flex flex-wrap md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mt-7 mb-2'>
               <button className='px-6 py-3 bg-[#32324e] text-white rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]'>Submit </button>
            </div> 
            </div> 
                </form>
            </div>

            </div>

            <div className='w-4/12 md-lg:w-full sm:w-full'>
             <div className='flex justify-start items-start flex-col bg-[#f0f0f0] p-5'>

            <div className='header flex justify-start items-start text-left flex-col'>
            <h2 className='font-normal text-3xl text-[#333] pb-3 md-lg:text-[24px]'>Am I Eligible?</h2>
           
            </div>

            <div>
            <p className='text-md text-[#68666C] mb-3' >The National Disability Insurance Scheme ‘NDIS’ provides SDA funding for people aged between 18 and 65 with very high support needs. To learn more about your eligibility, click on the button below.</p>
            <Link className='px-6 py-3 bg-[#32324e] text-white rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]'>View our Eligibility Guide</Link>
            </div>
            
        </div>
            </div>
            
        </div>
        </div>
        </div>

            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Eligibility;