

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './assets/css/custom.css'
import Home from './pages/Home';
import Ourhome from './pages/Ourhome';
import Understandingndis from './pages/Understandingndis';
import Valuesmission from './pages/Valuesmission';
import Whatwedo from './pages/Whatwedo';
import Meetourteam from './pages/Meetourteam';
import Collaboratewithus from './pages/Collaboratewithus';
import Eligibility from './pages/Eligibility';
import Contactus from './pages/Contactus';
import NotFound from './pages/NotFound';




function App() {


  return (
   <BrowserRouter >
   
   <Routes>
   <Route path='/' element={<Home/>}></Route>
   <Route path='/ourhome' element={<Ourhome/>}></Route>
   <Route path='/understanding-ndis' element={<Understandingndis/>}></Route>
   <Route path='/who-we-are/values-mission' element={<Valuesmission/>}></Route>
   <Route path='/who-we-are/what-we-do' element={<Whatwedo/>}></Route>
   <Route path='/who-we-are/meet-our-team' element={<Meetourteam/>}></Route>
   <Route path='/who-we-are/collaborate-with-us' element={<Collaboratewithus/>}></Route>
   <Route path='/eligibility' element={<Eligibility/>}></Route>
   <Route path='/contactus' element={<Contactus/>}></Route>
   <Route path='*'  element={<NotFound/>}></Route>
   </Routes>
   </BrowserRouter>
  );
}

export default App;
