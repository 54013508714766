import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const Meetourteam = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 

    const teams = [1,2,3,4]

    return (
        <div>
          <PageTitle title="Meet Our Team - Nexus Living SA"/>
            <Header/>
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

            <div className='w-full'>
            <div className='package-blog bg-[#fff] py-10'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-center items-center flex-col'>

        

           <div className='flex flex-wrap mt-10'>

           {
                teams.map((p, i) =>  <div key={i} className='w-3/12 md-lg:w-6/12 sm:w-full md-lg:mb-5'>
                        
                <div className='pack-box border-[1px] border-[#ccc] mx-3'>
                <img src="https://nexuslivingsa.com.au/images/team1.jpg" alt="" />
              <div className='pack-inner p-6 min-h-[170px] md-lg:min-h-full bg-white'>
                <h2 className='font-semibold text-2xl text-[#333] pb-3 md-lg:text-[22px]'>Bree Lawrence </h2>
                <p className='text-md text-[#68666C] mb-3'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
              </div>
              </div>

                </div>
                     )
                }

            
           </div>
            
        </div>
        </div>
        </div>
        </div>

            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Meetourteam;