import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF} from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { SlSocialInstagram } from "react-icons/sl";
import axios from 'axios';



const Footer = () => {
 

   // State to store the fetched data // start Axios
  const [data, setData] = useState([]);

  // Function to fetch data using Axios
  const fetchData = async () => {
    try {
      const response = await axios.get("https://nexuslivingsa.com.au/backend/public/api/footer");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  };

  // Call fetchData on component mount
  useEffect(() => {
    fetchData();
  }, []);

// End Axios


    return (
        <footer className='bg-[#191B1E]'>
            {data.map((post) => (
                <div key={post.id}>
            <div className='w-[85%] flex flex-wrap mx-auto border-b-[1px] border-[#2D2D39] py-16 md-lg:pb-10 sm:pb-6 text-sm'>
                <div className='w-3/12 lg:w-4/12 sm:w-full'>
                    <div className='flex flex-col gap-3'>
                    <h2 className='text-[#898989] uppercase tracking-widest  font-light text-md mb-2'>Contact us </h2>
                        <ul className='flex flex-col gap-2 text-[#898989] text-md'>
                        
                            <li>{post.short_description}</li><br></br>
                            <li>Address : {post.address}</li>
                            <li>Phone : {post.number}</li>
                            <li>Email : {post.email}</li>
                            
                        </ul> 
                    </div> 
                </div>
     
                <div className='w-5/12 lg:w-8/12 sm:w-full'>
                <div className='flex justify-center sm:justify-start sm:mt-6 w-full'>
                        <div>
                <h2 className='text-[#898989] uppercase tracking-widest  font-light text-md mb-2'>Usefull Links </h2>
                <div className='flex justify-between gap-[80px] lg:gap-[40px]'>
                    <ul className='flex flex-col gap-2 text-[#898989] text-md'>
                        <li>
                            <Link to='/' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Home  </Link>
                        </li>
                        
                        <li>
                            <Link to='/ourhome' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Our Homes </Link>
                        </li>
                        <li>
                            <Link to='/eligibility' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Eligibility </Link>
                        </li>
                        <li>
                            <Link to='/understanding-ndis' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Understanding the NDIS </Link>
                        </li>
                        <li>
                            <Link to='/contactus' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Contact Us  </Link>
                        </li>
                    </ul>

                    <ul className='flex flex-col gap-2 text-[#898989] text-md'>
                        <li>
                            <Link to='/who-we-are/values-mission' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Our Values & Mission </Link>
                        </li>
                        <li>
                            <Link to='/who-we-are/what-we-do' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>What We do </Link>
                        </li>
                        <li>
                            <Link to='/who-we-are/meet-our-team' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Meet Our Team  </Link>
                        </li>
                        <li>
                            <Link to='/who-we-are/collaborate-with-us' className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500'>Collaborate with us  </Link>
                        </li>
                    
                    </ul>

                </div>
                        </div> 
                    </div>
                </div>

                <div className='w-4/12 lg:w-full lg:mt-6'>
                <div className='w-full flex flex-col justify-start gap-5'>
   
                    <h2 className='text-[#898989] uppercase tracking-widest  font-light text-md mb-2'>Follow us </h2>
                  
                    
                     <ul className='flex justify-start items-center gap-3'>
                        
                        <li>
                            <a className='w-[38px] h-[38px] hover:bg-[#ae9c81] hover:text-white flex justify-center items-center bg-white rounded-full hover:transition-all hover:ease-out hover:duration-500' target='blank' href={post.facebook}><FaFacebookF/> </a>
                        </li>

                        <li>
                            <a className='w-[38px] h-[38px] hover:bg-[#ae9c81] hover:text-white flex justify-center items-center bg-white rounded-full hover:transition-all hover:ease-out hover:duration-500' target='blank' href={post.twitter}><FaTwitter/> </a>
                        </li>
                        <li>
                            <a className='w-[38px] h-[38px] hover:bg-[#ae9c81] hover:text-white flex justify-center items-center bg-white rounded-full hover:transition-all hover:ease-out hover:duration-500' target='blank' href={post.instagram}><SlSocialInstagram /> </a>
                        </li>
                     

                     </ul>
                     <div className='w-[150px]'>
                     <img src="https://nexuslivingsa.com.au/images/ndis-provider.png" alt="" />
                     </div>
                </div> 
            </div>

            </div>
 
         
            <div className='w-[90%] flex flex-wrap justify-center items-center text-sm text-[#898989] mx-auto py-5 text-center'>
                <span className='w-full'>{post.copyright} </span>
                <span>Website Developed by <Link className='text-white hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' target='blank' to={'https://digitaldotsmedia.com.au'}>Digital Dots Media</Link> </span>
            </div>
            </div>
            ))}
        </footer>
    );
};

export default Footer;