import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Homeother from '../components/Homeother';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const OurHome = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 

    return (
        <div>
            <PageTitle title="Our Home - Nexus Living SA"/>
            <Header/>
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

            <div className='third-blog bg-[#f0f0f0] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-start items-start flex-col'>

            <div className='header flex justify-start items-start text-left flex-col'>
            <h2 className='font-normal text-3xl text-[#333] pb-3 md-lg:text-[24px]'>Disability Housing in Adelaide, SA</h2>
           
            </div>

            <div>
            <p className='text-md text-[#68666C] mb-3' >Where you live, should feel like home. A place to find comfort and feel safe… your happy place! Home should be the environment you choose, with the space and freedom to allow both privacy and interaction with friends and family.</p>
            <p className='text-md text-[#68666C] mb-3' >Sana Living is committed to providing SDA that sets a benchmark for all Australians living with a disability to as many locations as possible, including Adelaide and throughout regional South Australia.</p>
            <p className='text-md text-[#68666C] mb-3' >We provide a combination of shared and individual disability accommodation in Adelaide and regional South Australia that sets a standard of quality and style.</p>

            </div>
            
        </div>
        </div>
        </div>

        <Homeother/>

            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default OurHome;