import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const Whatwedo = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 

    return (
        <div>
            <PageTitle title="What We Do - Nexus Living SA"/>
            <Header/>
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

            <div className='ndis-page bg-[#fff] py-14'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>

            <div className='w-8/12 md-lg:w-full sm:w-full pr-10 md-lg:mb-6 md-lg:pr-0 sm:pr-0'>
            <h2 className='font-normal text-3xl text-[#333] pb-3 md-lg:text-[24px]'>What We Do</h2>
            <p className='text-md text-[#68666C] mb-3' >We are a premium provider of Specialist Disability Accommodation. </p>
            <p className='text-md text-[#68666C] mb-3' >We work closely with those who have a goal to live more independently, offering help and guidance through the entire process.</p>
            <p className='text-md text-[#68666C] mb-3' >We are able to provide support to participants wanting to explore their eligibility for our homes. Our team connects people to a valued network of therapists and support coordinators who can assist in applying for SDA funding within an NDIS plan. We have available both medium term and long term accommodation options.</p>
            <p className='text-md text-[#68666C] mb-3' >We are here to help from our first meeting, right through to the time NDIS funding is approved, to moving day and long after. </p>

            </div>

            <div className='w-4/12 md-lg:w-full sm:w-full'>
            <img className='w-full md-lg:w-full' src="https://nexuslivingsa.com.au/images/whatwedo.jpg" alt="" />
            </div>
            
        </div>
        </div>
        </div>

            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Whatwedo;