import React from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import GoToTop from '../components/GoToTop';
import Footer from '../components/Footer';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div>
            <PageTitle title="Meet Our Team - Nexus Living SA"/>
            <Header/>
            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>
        
        <div className='w-full bg-white'>
            <div className='w-[85%] lg:w-[90%] mx-auto'>
            <div className='flex justify-center items-center flex-col py-10'>
            <h1 className="items-center text-[30px]">Page is Not Found</h1>
            <h1 className="items-center font-semibold text-[40px]">404</h1>
        </div>
        </div>
        </div>
        <Footer/>
        <GoToTop/>
        </div>
    );
};

export default NotFound;