import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GoToTop from '../components/GoToTop';
import PageTitle from '../components/PageTitle';
import axios from 'axios';
import Loading from '../components/Loading';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const Contactus = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 


    // State to store the form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const fname = useRef("");

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    fname.current.value = "";

    try {
      const response = await axios.post("https://nexuslivingsa.com.au/backend/public/api/contact", formData);
      console.log("Post created:", response.data);
      
    } catch (error) {
      console.error("Error creating post:", error);
    }
    
  };


//Other data
// State to store the fetched data // start Axios
const [data, setData] = useState([]);

// Function to fetch data using Axios
const fetchData = async () => {
  try {
    const response = await axios.get("https://nexuslivingsa.com.au/backend/public/api/footer");
    setData(response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  
};

// Call fetchData on component mount
useEffect(() => {
  fetchData();
}, []);


const [isLoading, setIsLoading] = useState(true);
 
    setTimeout(() => {
        setIsLoading(false);
    }, 2000);



    return (
        <div>
            <PageTitle title="Contact Us - Nexus Living SA"/>
            <Header/>

            <section className='bg-[url("https://nexuslivingsa.com.au/images/banner/Sana-slider5.jpg")] pt-[250px] md-lg:pt-[160px] sm:pt-[100px] bg-cover bg-no-repeat relative bg-left after:absolute after:left-0 after:bottom-0 after:contents after:right-0 after:top-0 after:bg-[#24222242]'>
        <div className=''>
            <div className='w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto'>
                <div className='flex flex-col justify-start items-start gap-1 z-10 relative text-white'>
            <h2 className='text-3xl font-normal flex'>Our Home  </h2>
            <div className='flex justify-start items-center text-[#333] gap-1 uppercase text-md w-full bg-[#f0f0f0] p-2' >
                    <Link className='hover:text-[#ae9c81] hover:transition-all hover:ease-out hover:duration-500' to='/'>Home</Link>
                    <span className=''>
                    <IoIosArrowForward />
                    </span>
                    <span>Our Home  </span>
                 </div>
                </div>
            </div>
          </div>

       </section>

    
            <div className='ndis-page bg-[#fff]'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>

            <div className='w-full md-lg:w-full sm:w-full'>
            
            <div className='boxshadowone p-10 m-16 md-lg:m-0 md-lg:mb-8'>
           
            <form onSubmit={handleSubmit}>
            <div className='flex md:flex-col md:gap-2 mb-5 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="name">Enter Your Name </label>
                <input ref={fname} type="text" value={formData.name} onChange={handleChange} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="name" placeholder='Enter Your Name' /> 
            </div>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="email"> Enter Your Email </label>
                <input ref={fname} type="email" value={formData.email} onChange={handleChange} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="email" placeholder='Enter Your Email' /> 
            </div>
            </div>

            <div className='flex md:flex-col md:gap-2 mb-5 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="subject">Enter Your Subject </label>
                <input ref={fname} type="text" value={formData.subject} onChange={handleChange} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="subject" placeholder='Enter Your Subject' /> 
            </div>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <label htmlFor="phone"> Enter Your Phone </label>
                <input ref={fname} type="phone" value={formData.phone} onChange={handleChange} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="phone" placeholder='Enter Your Phone' /> 
            </div>
            </div>

            <div className='flex md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2 w-full'>
                <textarea ref={fname} rows={5} value={formData.message} onChange={handleChange} className='w-full px-3 py-2 border border-slate-200 outline-none focus:border-[#ae9c81] rounded-md' name="message" placeholder='Enter Your Message'></textarea>
            </div>
         
            </div>

            <div className='flex flex-wrap md:flex-col md:gap-2 w-full gap-5 text-slate-600'>
            <div className='flex flex-col gap-1 mb-2'>
               <button type="submit" className='px-6 py-3 bg-[#32324e] text-white uppercase rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]'>Submit </button>
            </div> 
            </div> 
                </form>
            </div>

            </div>

       
            
        </div>
        </div>
        </div>

        {
isLoading ? ( <Loading/> ) : (
    
        <div className='ndis-page bg-[#fff]'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex flex-wrap'>
        {data.map((post) => (
               
            <div key={post} className='boxshadowone w-full p-10'>
           
          <div className='grid grid-cols-3 md-lg:grid-cols-1'>
           <div className='flex flex-col justify-center items-center'>
           <img className='h-[45px] mb-4' src="https://nexuslivingsa.com.au/images/icons/contact_icon01.png" alt="" />
           <h2 className='font-normal text-xl text-[#333] pb-2 md-lg:text-[20px] uppercase'>Address line</h2>
           <p className='text-md text-[#68666C] mb-6'>{post.address}</p>
           </div>
           <div className='flex flex-col justify-center items-center'>
           <img className='h-[45px] mb-4' src="https://nexuslivingsa.com.au/images/icons/contact_icon02.png" alt="" />
           <h2 className='font-normal text-xl text-[#333] pb-2 md-lg:text-[20px] uppercase'>Phone Number</h2>
           <p className='text-md text-[#68666C] mb-6'>{post.number}</p>
           </div>
           <div className='flex flex-col justify-center items-center'>
           <img className='h-[45px] mb-4' src="https://nexuslivingsa.com.au/images/icons/contact_icon03.png" alt="" />
           <h2 className='font-normal text-xl text-[#333] pb-2 md-lg:text-[20px] uppercase'>Mail Address</h2>
           <p className='text-md text-[#68666C] mb-6'>{post.email}</p>
           </div>
          </div>

            </div>
         ))}
        </div>
        </div>
        </div>

)
}
 
            <Footer/>
          
            <GoToTop/>

            
        </div>
    );
};

export default Contactus;