import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const Whoweare = () => {
    return (
        <div className='w-full'>
        <div className='first-blog  relative md-lg:after:hidden after:absolute after:w-[50%] after:h-full after:bg-[#32324e] after:top-0 after:right-0 after:content after:bottom-0 after:-z-10'>

         <div className='w-[85%] lg:w-[90%] mx-auto'>
            <div className='flex flex-wrap'>
                <div className='w-6/12 md-lg:w-full'>
                <div className='flex justify-start items-center'>
                <Fade left>
                    <div className='py-14 pr-8 md-lg:p-7'>
                        <h2 className='font-semibold text-3xl text-[#32324e] pb-5 md-lg:text-[24px]'>Creating a life that’s yours.</h2>
                        <p className='text-md text-[#68666C] mb-3'>Until now there has been limited choice for those living with a disability. Our multi-award-winning homes change that.</p>
                        <p className='text-md text-[#68666C] mb-3'>Our goal is to help you reach yours. We provide NDIS housing rentals, both medium and long-term, for people seeking to change their life.</p>
                        <p className='text-md text-[#68666C] mb-3'>Sana Living’s SDA properties offer our clients increased independence, comfort and choice about where and how they live.</p>
                        <p className='text-md text-[#68666C] mb-3'>The clever designs of our NDIS homes also enable greater opportunities for individuals to connect with others.</p>
                        <Link to='/contactus' className='px-6 py-3 bg-[#32324e] text-white rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]'>Get In Contact</Link>
                    </div>
                    </Fade>
                    </div>
                </div>
                <div className='w-6/12 md-lg:w-full'>
                <div className='flex justify-start items-center'>
                <Fade right>
                    <div className='py-14 pl-10 md-lg:p-7 bg-[#32324e]'>
                        
                        <h2 className='font-semibold text-3xl text-[#fff] pb-5 md-lg:text-[24px]'>What we do.</h2>
                        <p className='text-md text-[#ffffffa3] mb-3'>We provide NDIS housing support and offer a range of Specialist Disability Accommodation (SDA) options. We work closely with NDIS participants who have a goal to live more independently, offering help and guidance through the entire process. We assist participants wanting to explore their eligibility for NDIS housing options and our SDA homes. Our team connects people to a valued network of therapists and support coordinators who can assist with applications for NDIS rental housing in WA, SA or QLD, within an NDIS plan.</p>
                        <p className='text-md text-[#ffffffa3] mb-3'>Sana Living is dedicated to assisting eligible participants make the move to a more comfortable life. For those unfamiliar with SDA and the steps involved, we hold regular information sessions for participants, care providers and family members.</p>
                    
                    </div>
                    </Fade>
                    </div>
                </div>
            </div>
         </div>
            
        </div>
        
        <div className='second-blog bg-[#e4e4e4] py-10'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-center items-center flex-col'>
        <Fade bottom>   
            <div className='header flex justify-center items-center flex-col'>
            
            <h2 className='font-semibold text-3xl text-[#333] pb-3 md-lg:text-[24px]'>Who We Are</h2>
            <div className='w-[170px] items-center flex justify-center flex-col'>
                <div className='w-[170px] h-[1px] bg-[#a3a3a3]'></div>
                <div className='w-[50px] h-[7px] bg-[#ae9c81] relative bottom-1'></div>
            </div>
           
            </div>
           
            <div className='text-center my-8'>
            <p className='text-md text-[#68666C] mb-3' >Your challenges are our expertise. Our national team is experienced in delivering innovation, quality and excellence. We care about the people we help find homes for and will work to deliver more choices and better lives.</p>
            <Link className='px-6 py-3 bg-[#32324e] text-white rounded-3xl mt-5 inline-block hover:transition-all hover:ease-out hover:duration-500 hover:text-[#ae9c81]' to='/who-we-are/meet-our-team'>Meet Our Team</Link>
            </div>
            </Fade>
        </div>
        </div>
        </div>

        <div className='third-blog bg-[#fff] py-10'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-center items-center flex-col'>

            <div className='header flex justify-center items-center flex-col'>
            <h2 className='font-semibold text-3xl text-[#333] pb-3 md-lg:text-[24px]'>Live with Confidence</h2>
            <div className='w-[170px] items-center flex justify-center flex-col'>
                <div className='w-[170px] h-[1px] bg-[#a3a3a3]'></div>
                <div className='w-[50px] h-[7px] bg-[#ae9c81] relative bottom-1'></div>
            </div>
            </div>

            <div className='text-center my-8'>
            <p className='text-md text-[#68666C] mb-3' >In 2016, the NDIS began rolling out funding for approved NDIS participants requiring Specialist Disability Accommodation (or SDA). This fell under 4 design categories, including Improved Livability, Fully Accessible, Robust and High Physical Support.</p>
            <p className='text-md text-[#68666C] mb-3' >As a registered Specialist Disability Accommodation provider under the NDIS, we provide a range of NDIS housing for rent. These currently include options in 3 categories: Improved Liveability, High Physical Support and Fully Accessible.</p>
            </div>
            
        </div>
        </div>
        </div>



        </div>  

    );
};

export default Whoweare;