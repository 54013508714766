import React from 'react';
import LoaderIcon from '../loader.svg';

const Loading = () => {
    return (
        <div className='w-full'>
 
         <div className='w-[85%] lg:w-[90%] mx-auto'>
            <div className='flex w-full justify-center md-lg:justify-center items-center'>
            <img className='w-[140px] h-[140px]' src={LoaderIcon} alt='' /> 
          
                </div>
                </div>
            
                </div>
    );
};

export default Loading;