import React from 'react';
import Fade from 'react-reveal/Fade';

const Homeother = () => {
    return (
        <div className='w-full'>
            <div className='other-blog py-16'>
        <div className='w-[85%] lg:w-[90%] mx-auto'>
        <div className='flex justify-center items-center flex-col flex-wrap'>
        <Fade left>
           <div className='flex flex-wrap boxshadowone'>
           
            <div className='w-4/12 md-lg:w-full'>
                <img className='w-full md-lg:w-full' src="https://nexuslivingsa.com.au/images/adelaide-img1.jpg" alt="" />
            </div>
            <div className='w-8/12 md-lg:w-full'>
            
              <div className='pack-inner p-6 min-h-[170px] bg-white'>
                <h2 className='font-semibold text-2xl text-[#333] mb-5 pl-2 capitalize md-lg:text-[22px] border-l-[3px] border-[#333]'>   A Trusted Disability Housing Provider  </h2>
                <p className='text-md text-[#68666C] mb-3'> Sana Living have a reputation for quality, and everything about our SDA housing in Adelaide reflects that.</p>
                <p className='text-md text-[#68666C] mb-3'>We offer a selection of disability rental housing, so that ultimately the choice is yours when it comes to where, how and with whom you live.</p>
                <p className='text-md text-[#68666C] mb-3'>Currently our SDA in Adelaide includes villas, duplexes and houses and we are constantly sourcing desirable locations to provide a greater range of disability housing options in South Australia.</p>
              </div>
             
            </div>
            
           </div>
           </Fade>
           <Fade right>
           <div className='flex flex-wrap mt-10 boxshadowone'>
            
            <div className='w-8/12 md-lg:w-full'>
            <Fade right>
              <div className='pack-inner p-6 min-h-[170px] bg-white'>
                <h2 className='font-semibold text-2xl text-[#333] mb-5 pl-2 capitalize md-lg:text-[22px] border-l-[3px] border-[#333]'>   Committed To Your Needs   </h2>
                <p className='text-md text-[#68666C] mb-3'> Sana Living homes are designed for function and accessibility, with lifestyle and luxury in mind. Our specialist designers carefully consider the local environment, land size, and natural light to create a home with generously sized spaces that feels comfortable and bright.</p>
                <p className='text-md text-[#68666C] mb-3'>Committed to finishing our homes with assistive technology, quality appliances and contemporary fixtures, each of our disability housing options incorporate the latest technology and trends. We cater for a range of physical and psychosocial disabilities, which will enable you to live as independently as possible.</p>
              </div>
              </Fade>
            </div>
            <div className='w-4/12 md-lg:w-full'>
                <img className='w-full md-lg:w-full' src="https://nexuslivingsa.com.au/images/adelaide-img2.jpg" alt="" />
            </div>
           </div>
           </Fade>
            
        </div>
        </div>
        </div>
        </div>
    );
};

export default Homeother;